import React, { useState, useEffect } from "react"
// import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../../components/checkout"

import ReactCourse from "../../pics/react-summer-wars.png"
import Instalments from '../../components/instalments.js'

import Contacts from "../../components/contactform"
import Covid from "../../components/covid"

const ReactCrashCourseOnline = () => {
	let summerWars =
	"https://barcelonacodeschool.com/files/pics/react-summer-wars.png"
	// let [courses, setCourses] = useState([])

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/reactCrash')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])

	// let renderCourses =()=> {
	// 	return courses[0].startdates.map((date,i)=>(
	// 		<div key={i}className="event" >
	// 		<span>
	// 		<h4>{date}</h4>
	// 		<h2>{courses[0].title}</h2>
	// 		<p>{courses[0].shortdesc}</p>
	// 		</span>
	// 		<span>
	// 		<p>{courses[0].fulldesc1}</p>
	// 		<p>{courses[0].fulldesc2}</p>
	// 		<Link to="/register" state={{course: courses[0].title, date:date, cost:courses[0].signupprice, images:[courses[0].image]}}>
	// 		<button style={{'marginBottom': '1em'}}>Sign up</button>
	// 		</Link>
	// 		<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
	// 		</span>
	// 		</div>
	// 		))
	// }

	return (
		<div className="flexContent">
		<Helmet
		title="React.js Online Crash Course in Barcelona Code School"
		meta={[
			{
				name: "description",
				content:
				"React.js Online Crash Course in Barcelona Code School is a 2-week intensive programming course from Barcelona Code School. Fast-paced practical training aimed at students familiar with JavaScript. This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.",
			},
			{
				name: "keywords",
				content:
				"programming with react course, react course, web development with react in barcelona",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				property: "og:title",
				content:
				"React.js Online Crash Course in Barcelona Code School",
			},
			{
				property: "og:description",
				content:
				"A 2-week intensive online programming course from Barcelona Code School. Fast-paced practical training aimed at students familiar with JavaScript. This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.",
			},
			{
				property: "og:image",
				content:
				"https://barcelonacodeschool.com/static/react-summer-wars-6feef65e3f540e1f491fc571fc3a0cd1.png",
			},
			{
				property: "og:url",
				content:
				"https://barcelonacodeschool.com/reactjs-crash-course/",
			},
			{ name: "twitter:card", content: "summary_large_image" },
			]}
			script={[{ src: "https://js.stripe.com/v3/" }]}
			/>



			<h1 className='transparentContainer flex1200'>React.js <span className="highlighter">Online</span> Crash Course in Barcelona Code School</h1>

			<div className="fulltimeOnlineBanner flex1200">
			<p className="stamp">ONLINE</p>
			<img
			className="banners offsetImage"
			src={ReactCourse}
			style={{'width':'99%'}}
			alt="React.js Crash Course in Barcelona Code School"
			/>
			</div>


			<h2 className='transparentContainer flex450'>Front-end web development with React.js!</h2>

			<h2 className='transparentContainer flex225'>Flexible, <span className="highlighter">mentored</span>,
			part-time, online</h2>








			<div className="socialsharebuttons transparentContainer">
			<div className="socialshare">
			<a
			className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='React Intensive Course in Barcelona Code School'&url=https://barcelonacodeschool.com/reactjs-crash-course/"
			data-size="large"
			>
			<button>tweet</button>
			</a>
			</div>

			<div
			className="fb-share-button socialshare"
			data-href="https://developers.facebook.com/docs/plugins/"
			data-layout="button"
			data-size="small"
			>
			<a
			target="_blank"
			href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/reactjs-crash-course/"
			className="fb-xfbml-parse-ignore"
			>
			<button>facebook</button>
			</a>
			</div>
			</div>


			<article className='whiteCard flex600'>
			<div className='whiteCard'>
			<ul>
			<li>
			2-week <span className="highlighter">mentored</span>{" "}
			part-time hands-on training
			</li>
			<li>2 personal 1:1 sessions</li>
			<li>
			Support platform for code-related questions available
			24/7
			</li>
			<li>The course is taught in English</li>
			<li>From zero to 👸 and 🤴</li>
			<li>15% theory / 85% practice / 100% efficiency</li>
			<li>13 unique video-lessons</li>
			<li>20 coding challenges to complete</li>
			<li>All the materials are in English</li>
			<li>
			Learn how to build fast and powerful client apps with
			React
			</li>
			<li>6 months access to the materials</li>
			<li>Certificate of completion</li>
			</ul>
			</div>
			</article>

			

			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Overall concept</li>
			<li>JSX syntax</li>
			<li>create-react-app</li>
			<li>Structuring React app</li>
			<li>Components-based approach and reusability</li>
			<li>Class and functional components</li>
			<li>Props</li>
			<li>State</li>
			<li>Events and handlers</li>
			<li>Lifecycle methods</li>
			<li>Hooks</li>
			<li>Async requests</li>
			<li>Promises</li>
			<li>Conditional rendering</li>
			<li>React router</li>
			<li>Metadata in React</li>
			<li>Using external components</li>
			<li>Production build, deployment</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<p>
			Solid understanding of JavaScript logic and syntax;
			functions, loops, conditionals, arrays, objects.
			</p>
			<p>
			Good knowledge of HTML/CSS: DOM, structuring HTML, CSS
			selectors, Grid, Flexbox.
			</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<p>
			After this course you will have a strong understanding of
			the <strong>logic</strong>, <strong>syntax</strong> and{" "}
			<strong>usage</strong> of ReactJS.{" "}
			</p>
			<p>
			You will be able to build React apps from scratch and have
			progress further into the depths of React or any other
			front-end JS library/framework such as Angular, Vue, Ember
			or anything else.
			</p>
			</div>
			</article>


			<article className='transparentContainer flex225'>
			<h2>A Wise quote</h2>
			<div className='whiteCard'>
			<p>"Don’t worry if it doesn’t work right. If everything did, you’d be out of a job." </p>
			<p>– Mosher’s Law of Software Engineering</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>750€</h2>
			<p>100% is paid upon registration.</p>
			<Instalments/>
			</div>
			</article>



			<article className='transparentContainer flex450'>
			<h2>How it works</h2>
			<div className='whiteCard'>
			<ul>
			<li>Sign up through this page</li>
			<li>Fill out student's form we will send you</li>
			<li>
			Get the materials including texts, videos and exercises with
			tests
			</li>
			<li>Start learning</li>
			<li>
			Access support platform 24/7 to post code-related questions
			and get help
			</li>
			<li>Book your 1:1 sessions from our calender</li>
			<li>
			Go through the lectures and complete exercises for each
			block of the curriculum
			</li>
			<li>Test your solutions with the provided automatic tests</li>
			<li>Push your code to GitLab to get feedback/review from us</li>
			<li>Get yourt certificate once finished</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex900 flexContent">
			<h2 id="dates">Access</h2>
			<div className="whiteCard">
			<span>
			<h2>
			React.js Crash Course{" "}
			<span className="highlighter">online</span> version
			</h2>
			<p>
			Flexible{" "}
			<span className="highlighter">self-paced</span>,
			part-time, online
			</p>
			</span>
			<span>
			<Link
			to="/register"
			state={{
				course: "React.js Online Crash Course",
				date: new Date().toLocaleDateString(),
				cost: 750,
				images: [summerWars],
			}}
			>
			<button style={{ marginBottom: "1em" }}>
			Sign up
			</button>
			</Link>
			<p className="finePrint">
			By signing up you agree to the{" "}
			<a href="/about-us/terms-and-conditions/">
			Terms and Conditions
			</a>{" "}
			stated on the website.
			</p>
			</span>
			</div>
			</article>

			<article className='flex450'>
			<Contacts
			title={"Inquiry about the course"}
			subject={"Inquiry about React.js Crash Course Online"}
			/>
			</article>
			</div>
			)
}

export default ReactCrashCourseOnline
